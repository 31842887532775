



































































































































































































































































































































// @vuese
// @group Views
// Registration

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import VueRecaptcha from "vue-recaptcha";
import { validateEmailAddress } from "../components/utils/EmailParser";
import Component from "vue-class-component";
import GenericForm from '@/components/forms/GenericForm.vue';
import { Store } from "@/store/store";

@Component({
  components: {
    VuePhoneNumberInput,
    VueRecaptcha,
    GenericForm,
  },
  metaInfo: {
    title: 'Distributor Registration',
  }
})
/**
 * Intended for generic user registration
 */
export default class Registration extends Store {
  componentTitle = "Distributor Registration"
  profile: Parameters<typeof Store.prototype.applyForDistributorAccount>[0] = {
    company_name: {
      value: "",
      errors: []
    },
    address_line_1: {
      value: "",
      errors: []
    },
    email: {
      value: "",
      errors: []
    },
    city: {
      value: "",
      errors: []
    },
    state: {
      value: "",
      errors: []
    },
    country_id: {
      value: 0,
      errors: []
    },
    postcode: {
      value: "",
      errors: []
    },
    lead_contact: {
      value: "",
      errors: []
    },
    title: {
      value: "",
      errors: []
    },
    phone: {
      value: "",
      errors: []
    },
    company_registration_number: {
      value: "",
      errors: []
    },
    address_line_2: {
      value: "",
      errors: []
    },
    website: {
      value: "",
      errors: []
    },
    has_manual_invoices:{
      value: false,
      errors: []
    }
  };
  phoneNumber = "";
  verified = false; // Used to validate if the captcha was validated or not
  completed = false; // Toggles when the form is completed
  captchaKey: string | undefined = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
  showForm = false; // Toggles the main form render
  emailConfirmation = "";

  async mounted(){
      await this.getCountries()
  }
  /**
   * Submits the stored `profile` variable to the server
   * @vuese
   */
  async submit(){
    if(this.profile.email.value !== this.emailConfirmation){
      this.profile.email.errors?.push('Missmatching Emails')
      return 
    }
    // else if(!this.verified){
    //   this.$toast.error('You Must Verify The reCaptcha Before Progressing')
    //   return
    // }  

    this.applyForDistributorAccount(this.profile)
    .then(() => {
      this.$toast.success('Application Sent')
      this.showForm = false
      this.completed = true
    })
    .catch(({errors, ...rest}) => {
      this.clearFormErrors()
      Object.keys(errors).map((i:string)=>{
        //@ts-ignore
        this.profile[i].errors = errors[i]
      })
      this.$toast.error('There Was An Error Submitting The Application')
    }) 
  }

  /**
   * Fires when the phone number filed was updated
   * @arg {{formatInternational: string}} code
   * @vuese
   */
  phoneNumberUpdated(code: {formatInternational: string}){
    this.profile.phone.value = code.formatInternational // Format into +XX XXXXXXXXXX format
  }
  /**
   * To be invoked when the ReCapctha has been verified
   * @vuese
   */
  onVerify(){
    this.verified = true
  }
  /**
   * To be invoked when the ReCapctha has been expired
   * @vuese
   */
  onExpired(){
    this.verified = false
  }
  /**
   * Used to validate if the email is of an email type or not
   * @arg {String} email
   * @vuese
   * @returns {void}
   */
  validateEmail(email: string){
    this.setSending(true);
    this.clearFormErrors()
    if(validateEmailAddress(email).length != 1){
        this.setSending(false)
        this.showForm = false
        return
    }
    
    this.validateApplicationEmail(email).then(() => {
        this.setSending(false)
        this.showForm = true
    }).catch(({errors}) => {
        this.setSending(false)
        this.profile.email.errors = [errors.email]
        this.$toast.error('There Was An Error With This Request')
    })
  }

  /**
   * Clears all form errors from form
   * @vuese
   */
  clearFormErrors(){
    for(var i in this.profile){
        // @ts-ignore
        this.profile[i].errors = []
    }
  }
}
